<template>
  <v-card id="accountInformation">
    <v-card-title
      class="headline msaBlue white--text text-h6 pa-2 font-weight-bold"
    >
      Account Information
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="pb-0">
            <v-select
              v-model="edited.subscriptionTypeId"
              :disabled="disableAll"
              :items="subscriptionTypes"
              :rules="required"
              @change="onSubscriptionChanged"
              chips
              data-testid="account-type"
              dense
              item-text="label"
              item-value="id"
              label="Account Type"
              outlined
              placeholder="Select Account Type"
            />
          </v-col>
          <v-col class="pb-0">
            <v-switch
              v-model="edited.isTestAccount"
              :disabled="disableAll"
              class="my-0"
              data-testid="text-account"
              dense
              hide-details
              label="Test Account"
              outlined
            />
          </v-col>
          <v-col v-if="showFreemiumWarning" class="pt-0" cols="12">
            <v-alert dense dismissible elevation="2" type="warning">
              After save, all web and mobile accesses beyond the freemium
              license limits ({{ FREEMIUM_LICENSE_LIMIT }}) will be revoked.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="edited.subscriptionTermTypeId"
              :disabled="disableAll"
              :items="subscriptionTermTypes"
              :rules="required"
              chips
              data-testid="subscription-term-type"
              dense
              item-text="name"
              item-value="id"
              label="Subscription Term Type"
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="edited.subscriptionTerm"
              :disabled="disableAll"
              :rules="rules.positiveInteger"
              data-testid="subscription-term"
              dense
              label="subscription Term"
              outlined
              type="number"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              :disabled="disableAll"
              :nudge-right="40"
              dense
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="edited.subscriptionStartDate"
                  v-bind="attrs"
                  v-on="on"
                  :clearable="
                    edited.subscriptionTermTypeId ==
                    $constants.SUBSCRIPTION_TERM_TYPES['N/A']
                  "
                  :disabled="disableAll"
                  :rules="
                    edited.subscriptionTermTypeId ==
                    $constants.SUBSCRIPTION_TERM_TYPES['N/A']
                      ? []
                      : required
                  "
                  append-icon="mdi-calendar"
                  data-testid="subscription-start-date"
                  dense
                  label="Subscription Start Date"
                  outlined
                  readonly
                />
              </template>
              <v-date-picker
                v-model="edited.subscriptionStartDate"
                :max="edited.renewalDate == '' ? undefined : edited.renewalDate"
                @input="startDateMenu = false"
                dense
                no-title
              />
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="renewalDateMenu"
              :close-on-content-click="false"
              :disabled="disableAll"
              :nudge-right="40"
              dense
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="edited.renewalDate"
                  v-bind="attrs"
                  v-on="on"
                  :clearable="
                    edited.subscriptionTermTypeId ==
                    $constants.SUBSCRIPTION_TERM_TYPES['N/A']
                  "
                  :disabled="disableAll"
                  :rules="
                    edited.subscriptionTermTypeId ==
                    $constants.SUBSCRIPTION_TERM_TYPES['N/A']
                      ? []
                      : rules.renewalDate
                  "
                  append-icon="mdi-calendar"
                  data-testid="subscription-renewal-date"
                  dense
                  label="Subscription Renewal Date"
                  outlined
                  readonly
                  ref="renewalDate"
                />
              </template>
              <v-date-picker
                v-model="edited.renewalDate"
                :min="
                  edited.renewalDate == ''
                    ? undefined
                    : edited.subscriptionStartDate
                "
                @input="renewalDateMenu = false"
                dense
                no-title
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="edited.isStandardPricing"
              :disabled="disableAll"
              class="mt-0"
              data-test-id="standard-pricing"
              dense
              hide-details
              label="Standard Pricing"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-8" style="border-bottom: 1px black dashed">
            FEATURES
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" lg="4">
            <v-row align="center">
              <v-col>
                <v-switch
                  v-model="edited.metadataEnabled"
                  :disabled="disableAll"
                  class="mt-0"
                  data-testid="metadata-enabled"
                  dense
                  hide-details
                  label="Meta Data"
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-row align="center">
              <v-col>
                <v-switch
                  v-model="edited.isCourseBuilderEnabled"
                  :disabled="disableAll"
                  @change="toggleLmsFeatures($event)"
                  class="mt-0"
                  data-testid="course-builder-enabled"
                  dense
                  hide-details
                  label="Course Builder"
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-row align="center">
              <v-col>
                <v-switch
                  v-model="edited.contractorEnabled"
                  :disabled="disableAll"
                  @change="toggleContractorFeatures($event)"
                  class="mt-0"
                  data-testid="contractor-enabled"
                  dense
                  hide-details
                  label="Contractor"
                  outlined
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-row align="center">
              <v-col>
                <v-switch
                  v-model="edited.schedulerEnabled"
                  :disabled="disableAll"
                  class="mt-0"
                  data-testid="scheduler-enabled"
                  dense
                  hide-details
                  label="Scheduler"
                  outlined
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-row align="center">
              <v-col>
                <v-switch
                  v-model="edited.isDuplicateCoursesEnabled"
                  :disabled="disableDuplicateCourses || disableAll"
                  class="mt-0"
                  data-testid="duplicate-courses-enabled"
                  dense
                  hide-details
                  label="Duplicate Courses"
                  outlined
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-row align="center">
              <v-col>
                <v-switch
                  v-model="edited.contractorLmsEnabled"
                  :disabled="disableContractorLms || disableAll"
                  class="mt-0"
                  data-testid="contractor-online-courses-enabled"
                  dense
                  hide-details
                  label="Contractor Online Courses"
                  outlined
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-row align="center">
              <v-col>
                <v-switch
                  v-model="edited.labelEnabled"
                  :disabled="disableAll"
                  class="mt-0"
                  data-testid="labels-enabled"
                  dense
                  hide-details
                  label="Labels"
                  outlined
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-row align="center">
              <v-col>
                <v-switch
                  v-model="edited.isApiEnabled"
                  :disabled="disableAll"
                  class="mt-0"
                  data-testid="api-enabled"
                  dense
                  hide-details
                  label="API"
                  outlined
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-row align="center">
              <v-col>
                <v-switch
                  v-model="edited.isUserNotificationEnabled"
                  :disabled="disableAll"
                  class="mt-0"
                  data-testid="notifications-enabled"
                  dense
                  hide-details
                  label="Notifications"
                  outlined
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-row align="center">
              <v-col>
                <v-switch
                  v-model="edited.is1LifeMobileSettingEnabled"
                  :disabled="disableAll"
                  class="mt-0"
                  dense
                  hide-details
                  label="Mobile 2.0"
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-8" cols="12" style="border-bottom: 1px black dashed">
            ACCESS
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="inGroup" class="pb-0" cols="12">
            <v-alert
              class="white--text"
              color="msaBlue"
              dense
              elevation="2"
              type="info"
            >
              This company is part of the
              <span class="font-weight-bold">
                {{ edited.companyGroupName }}
              </span>
              .
              <br />
              Click
              <span
                @click="goToGroupDetail()"
                class="text-decoration-underline font-weight-bold"
                style="cursor: pointer"
              >
                here
              </span>
              to manage group licenses.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="left">
            <v-row align="center">
              <v-col> Status </v-col>
              <v-col>
                <v-btn-toggle
                  v-model="edited.status"
                  :style="{ width: '100%' }"
                  @change="onStatusToggle"
                  color="msaBlue"
                  mandatory
                >
                  <v-btn
                    :style="{ width: '50%' }"
                    :value="1"
                    active-class="msaBlue white--text"
                    class="text-capitalize"
                    outlined
                  >
                    Active
                  </v-btn>

                  <v-btn
                    :style="{ width: '50%' }"
                    :value="0"
                    active-class="msaBlue white--text"
                    class="text-capitalize"
                    outlined
                  >
                    Inactive
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="statusChanged" cols="12">
            <v-alert dense dismissible elevation="2" type="warning">
              {{ statusChangeWarning }}
            </v-alert>
          </v-col>
        </v-row>
        <v-form ref="form">
          <v-row v-if="!inGroup">
            <v-col>
              <v-text-field
                v-model="edited.licenses"
                :disabled="disableAll || isFreemium"
                :rules="rules.positiveInteger"
                data-testid="licenses"
                dense
                label="Licenses (z)"
                outlined
                type="number"
              />
            </v-col>
          </v-row>
          <v-row v-if="!inGroup">
            <v-col>
              <v-text-field
                v-model="edited.paidLicenses"
                :disabled="disableAll || isFreemium"
                :rules="rules.positiveInteger"
                data-testid="paid-licenses"
                dense
                label="Paid Licenses (y)"
                outlined
              />
            </v-col>
          </v-row>
          <v-row v-if="inGroup">
            <v-col>
              <v-text-field
                v-model="edited.groupLicenses"
                data-testid="licenses"
                dense
                disabled
                label="Licenses (z)"
                outlined
                type="number"
              />
            </v-col>
          </v-row>
          <v-row v-if="inGroup">
            <v-col>
              <v-text-field
                v-model="edited.groupPaidLicenses"
                data-testid="paid-licenses"
                dense
                disabled
                label="Paid Licenses (y)"
                outlined
                type="number"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="edited.activeUsersCount"
                dense
                label="Total Active Users (x)"
                outlined
                readonly
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-dialog v-model="cleanUpDataDialog" max-width="600px" persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" v-blur color="red" dark>
            clean up data
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="msaBlue white--text"> Confirm </v-card-title>
          <v-card-text class="mt-2">
            <v-row>
              <v-col cols="12">
                If you confirm, the company's filled out forms and assigned
                documents attached to the project named
                <strong>1Life Software Training</strong> will be archived.
              </v-col>
              <v-col>
                <v-text-field
                  v-model="confirmCleanUpData"
                  @paste.prevent
                  dense
                  outlined
                  placeholder='Type in "DELETE" then click confirm'
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="
                confirmCleanUpData = '';
                cleanUpDataDialog = false;
              "
              class="mr-5"
              color="red"
              text
            >
              cancel
            </v-btn>
            <v-btn
              :disabled="disableCleanUpData"
              @click="cleanUpData"
              color="msaBlue white--text"
            >
              confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-spacer />
      <v-btn
        :disabled="disableSave"
        @click="$emit('save')"
        class="msaBlue white--text"
        data-testid="save-button"
      >
        save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'AccountInformation',
  props: {
    companyDetail: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    disableSave: {
      type: Boolean,
    },
    disableAll: {
      type: Boolean,
    },
    subscriptionTypes: {
      type: Array,
    },
    subscriptionTermTypes: {
      type: Array,
    },
  },
  data() {
    return {
      edited: {},
      rules: {
        positiveInteger: [(v) => /^(0|[1-9]\d*)$/.test(v) || 'Invalid value'],
        renewalDate: [
          (v) => !!v || 'This field is required',
          (v) =>
            new Date(v).getTime() >=
              new Date(this.edited.subscriptionStartDate).getTime() ||
            'Renewal date must be after subscription start date',
        ],
      },
      startDateMenu: false,
      renewalDateMenu: false,
      statusChangeWarning: '',
      required: [(v) => !!v || 'This field is required'],
      FREEMIUM_LICENSE_LIMIT: this.$constants.FREEMIUM_LICENSE_LIMIT,
      cleanUpDataDialog: false,
      confirmCleanUpData: '',
    };
  },
  computed: {
    updated() {
      return {
        contractorEnabled: this.edited.contractorEnabled,
        contractorLmsEnabled: this.edited.contractorLmsEnabled,
        groupLicense: this.edited.groupLicense,
        groupPaidLicense: this.edited.groupPaidLicense,
        is1LifeMobileSettingEnabled: this.edited.is1LifeMobileSettingEnabled,
        isApiEnabled: this.edited.isApiEnabled,
        isCourseBuilderEnabled: this.edited.isCourseBuilderEnabled,
        isDuplicateCoursesEnabled: this.edited.isDuplicateCoursesEnabled,
        isStandardPricing: this.edited.isStandardPricing,
        isTestAccount: this.edited.isTestAccount,
        isUserNotificationEnabled: this.edited.isUserNotificationEnabled,
        labelEnabled: this.edited.labelEnabled,
        licenses: parseInt(this.edited.licenses),
        metadataEnabled: this.edited.metadataEnabled,
        paidLicenses: parseInt(this.edited.paidLicenses),
        renewalDate: this.edited.renewalDate,
        schedulerEnabled: this.edited.schedulerEnabled,
        status: this.edited.status,
        subscriptionStartDate: this.edited.subscriptionStartDate,
        subscriptionTerm: parseInt(this.edited.subscriptionTerm),
        subscriptionTermTypeId: this.edited.subscriptionTermTypeId,
        subscriptionTypeId: this.edited.subscriptionTypeId,
      };
    },
    disableContractorLms() {
      return (
        !this.edited.isCourseBuilderEnabled || !this.edited.contractorEnabled
      );
    },
    disableDuplicateCourses() {
      return !this.edited.isCourseBuilderEnabled;
    },
    showFreemiumWarning() {
      return (
        this.edited.subscriptionTypeId == 4 &&
        this.companyDetail.subscriptionTypeId != 4
      );
    },
    statusChanged() {
      return this.edited.status != this.companyDetail.status;
    },
    inGroup() {
      return (
        this.companyDetail.companyGroupId != '' &&
        this.companyDetail.companyGroupId > 0
      );
    },
    isFreemium() {
      return this.edited.subscriptionTypeId == 4;
    },
    disableCleanUpData() {
      return this.confirmCleanUpData !== 'DELETE';
    },
  },
  watch: {
    loading(val) {
      if (!val) {
        this.edited = {
          ...this.companyDetail,
        };
      }
    },
    edited: {
      handler() {
        this.$refs.renewalDate.validate();
        this.$emit('update', this.updated);
      },
      deep: true,
    },
  },
  methods: {
    toggleContractorFeatures(val) {
      if (!val) {
        this.edited.contractorLmsEnabled = false;
      }
    },
    toggleLmsFeatures(val) {
      if (!val) {
        this.edited.isDuplicateCoursesEnabled = false;
        this.edited.contractorLmsEnabled = false;
      }
    },
    onStatusToggle(val) {
      if (this.statusChanged) {
        if (!val) {
          this.statusChangeWarning =
            'This company will be inactive after save. All users will be inactivated.';
          this.edited.mobileAccess = false;
        } else {
          this.statusChangeWarning =
            'This company will be active after save. Users that were active will be reactivated.';
        }
      }
    },
    goToGroupDetail() {
      this.$store.commit('updateSelectedGroup', {
        id: this.companyDetail.companyGroupId,
        name: this.companyDetail.companyGroupName,
      });
      this.$router.push({
        name: 'IAGroupDetail',
        params: { id: this.companyDetail.companyGroupId },
      });
    },
    onSubscriptionChanged() {
      if (this.edited.subscriptionTypeId == 4) {
        this.edited.licenses = this.companyDetail.licenses;
        this.edited.paidLicenses = this.companyDetail.paidLicenses;
      }
    },
    cleanUpData() {
      const url = 'clean-up-data-for-company?format=json';
      const params = {
        companyId: this.$route.params.companyId,
        loaderText: 'Cleaning up data...',
        snackText: 'Data has been cleaned up successfully.',
      };
      this.$axios.post(url, params).then(() => {
        this.cleanUpDataDialog = false;
        this.confirmCleanUpData = '';
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
